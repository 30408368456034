import React from 'react';

const Header = ({ stage, cityName, stage1, stage2, stage3, stage4, gameTime }) => {
    let stageMessage = '';

    switch (stage) {
        case 1: 
            stageMessage = `Stage 1: Produce ${stage1} cars to unock Stage 2.`;
            break;
        case 2:
            stageMessage = `Stage 2: Earn $ ${stage2} in revenue to unlock Stage 3.`;
            break;
        case 3:
            stageMessage = `Stage 3: Earn $ ${stage3} in revenue to unock Stage 4.`;
            break;
        case 4: 
            stageMessage = `Stage 4: Earn $ ${stage4} in revenue to expand to next city.`;
            break;
        default:
            stageMessage = '';
    }

    return (
        <header className="header">
            <h1>Clagine</h1>
            <p>Welcome to <strong>{cityName}</strong>!</p>
            <div className='game-message'>
                <p>{stageMessage}</p>
            </div>
            <div className='game-time'>
                <p>Time Elapsed: {gameTime}</p>
            </div>
        </header>
    );
};

export default Header;
