import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Button from './components/Button';
import './App.css';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

function App() {

   // Load saved game state from localStorage or initialize a new state
   const loadGameState = () => {
    const savedGameState = localStorage.getItem('carClickerGameState');
    return savedGameState
      ? JSON.parse(savedGameState)
      : {
          carsProduced: 0,
          revenue: 0,
          availableFunds: 0,
          unsoldInventory: 0,
          salesForce: 0,
          stage: 1,
          factories: 0,
          roboticFactories: 0,
          showrooms: 0,
          upgrades: {
            productionEfficiency: 0,
            premiumCarModel: 0,
            luxuryCarModel: 0,
            salesTraining: 0,
            salesManager: 0,
          },
          activeAd: null,
          adTimeRemaining: 0,
          cooldownAd: false,
          cooldownAdTimeRemaining: 0,
          currentCityIndex: 0,
          elapsedTime: 0,
        };
  };

  const [gameState, setGameState] = useState(loadGameState);

  // Save game state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('carClickerGameState', JSON.stringify(gameState));
  }, [gameState]);

  const difficultyFactor = 1.2;
  const firstSalesPerson = Math.round(100 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const salesPersonMultiplier = 1.5;
  const firstFactory = Math.round(300 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const factoryMultiplier = 1.25;
  const firstRoboticFactory = Math.round(1000 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const roboticFactoryMultiplier = 1.5;
  const firstShowroom = Math.round(5000 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const showroomMultiplier = 1.5;
  const firstProductionEfficiency = Math.round(200 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const productionEfficiencyMultiplier = 2;
  const firstPremiumCarModel = Math.round(2000 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const premiumCarModelMultiplier = 1.5;
  const firstSalesTraining = Math.round(500 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const salesTrainingMultiplier = 1.5;
  const firstSalesManager = Math.round(20000 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const salesManagerMultiplier = 1.5;
  const firstLuxuryCarModel = Math.round(4000 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const luxuryCarModelMultiplier = 1.5;
  const carValue = 10;
  const premiumCarValue = 10;
  const luxuryCarValue = 30;
  const roboticFactoryProduction = 5; //Robotic factories produce 5 cars per second
  const tvAdCost = Math.round(105000 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const billboardAdCost = Math.round(70000 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const digitalAdCost = Math.round(52500 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  
  const stageProgressionFirst = Math.round(30 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const stageProgressionTwo = Math.round(10000 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const stageProgressionThree = Math.round(500000 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  const stageProgressionFour = Math.round(10000000 * Math.pow(difficultyFactor, gameState.currentCityIndex));
  

  const cityNames = [
    'Parin', 'Londona', 'Tokiana', 'Madrillo', 'Romina', 'Shangra', 'Chibarro', 'Moscavo', 'Mumbairis', 'Delivana',
    'Beijana', 'Berlynn', 'Amsteris', 'Vienora', 'Lisbaira', 'Manchino', 'Barcelis', 'Buenaro', 'Jakardo', 'Sydrani',
    'Seoulina', 'Stockharra', 'Oslino', 'Helsor', 'Copenhagel', 'Warsova', 'Praguevo', 'Istafor', 'Budapal', 'Athenis',
    'Zurichon', 'Dublina', 'Bruxiva', 'Edibourne', 'Florenze', 'Rio Solis', 'Veneta', 'Napolena', 'Milantis', 'Melbouris',
    'Casamora', 'Montevia', 'Haitora', 'Bostano', 'Chicara', 'Philavia', 'Hamburgo', 'Brisalon', 'Vancorra', 'Toronda',
    'Lagosina', 'Capetian', 'Kyotara', 'Bengalor', 'Dubaiya', 'Viennara', 'Athensis', 'Buchanara', 'Portor', 'Cairan',
    'Christano', 'Lucara', 'Singapora', 'Timbuka', 'Rabatish', 'Ankaris', 'Jakorvo', 'Bangkorra', 'Kuala Lima', 'Tashkora',
    'Darvasa', 'Urukala', 'Adelorna', 'Pretoville', 'Tangeris', 'Zambezia', 'Kinsharo', 'Amanyara', 'Monvis', 'Sevillora',
    'Turinova', 'Lyonis', 'Guadala', 'Algiros', 'Marran', 'Dakara', 'Maputara', 'Monavia', 'Cartella', 'Portaleza',
    'Caracore', 'Ottani', 'Managora', 'Lusanra', 'Telvora', 'Kansova', 'Shenzarro', 'Quebesco', 'Jakosa', 'Firenya'
  ];

  // Move to the next city
  const handleCityTransition = () => {
    setGameState((prevState) => ({
      ...prevState,
      currentCityIndex: (prevState.currentCityIndex + 1) % cityNames.length,
      carsProduced: 0,
      revenue: 0,
      availableFunds: 0,
      unsoldInventory: 0,
      salesForce: 0,
      stage: 1,
      factories: 0,
      roboticFactories: 0,
      showrooms: 0,
      upgrades: {
        productionEfficiency: 0,
        premiumCarModel: 0,
        luxuryCarModel: 0,
        salesTraining: 0,
        salesManager: 0,
      },
      activeAd: null,
      adTimeRemaining: 0,
      cooldownAd: false,
      cooldownAdTimeRemaining: 0,
      elapsedTime: 0,
    }));
  };
  //Reset Game
  const resetGame = () => {
    setGameState((prevState) => ({
      ...prevState,
      currentCityIndex: (0) % cityNames.length,
      carsProduced: 0,
      revenue: 0,
      availableFunds: 0,
      unsoldInventory: 0,
      salesForce: 0,
      stage: 1,
      factories: 0,
      roboticFactories: 0,
      showrooms: 0,
      upgrades: {
        productionEfficiency: 0,
        premiumCarModel: 0,
        luxuryCarModel: 0,
        salesTraining: 0,
        salesManager: 0,
      },
      activeAd: null,
      adTimeRemaining: 0,
      cooldownAd: false,
      cooldownAdTimeRemaining: 0,
      elapsedTime: 0,
    }));
  }
  //Number Format
  const numberFormat = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //Time Format
  function timeFormat(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes > 0 ? `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ` : ''}${remainingSeconds} ${remainingSeconds === 1 ? 'second' : 'seconds'}`;
  }

  //Game Timers
  useEffect(() => {
    if (gameState.stage >= 1 && gameState.stage <= 4) {
      const timerInterval = setInterval(() => {
        setGameState((prevState) => ({
          ...prevState,
          elapsedTime: prevState.elapsedTime + 1,
        }));
      }, 1000); 
  
      return () => clearInterval(timerInterval);
    }
    if (gameState.stage > 4) {
      const timerInterval = setInterval(() => {
        setGameState((prevState) => {
          if (prevState.stage > 4 && prevState.elapsedTime > 0) {
            clearInterval(timerInterval); 
          }
          return prevState; 
        });
      }, 1000);
  
      return () => clearInterval(timerInterval);
    }
  }, [gameState.stage]);

  //Produce Cars by Clicking
  const handleProduceCar = () => {
    const productionBoost = gameState.upgrades.productionEfficiency
    setGameState((prevState) => {
      const newCarsProduced = prevState.carsProduced + 1 + productionBoost;
      const newUnsoldInventory = prevState.unsoldInventory + 1 + productionBoost;
      let newStage = prevState.stage;
      let newSalesForce = prevState.salesForce;

      //Transition to Stage 2 if cars produced > 10
      if (newCarsProduced >= stageProgressionFirst && prevState.stage ===1) {
        newStage = 2;
        newSalesForce = 1; //Automatically add 1 salesperson
      }

      //Update game state
      return {
        ...prevState,
        carsProduced: newCarsProduced,
        unsoldInventory: newUnsoldInventory,
        stage: newStage,
        salesForce: newSalesForce,
      };
      
    });
  };

  //Sales Person: Hire & Cost
  const calculateSalesPersonCost = () => {
    return Math.round(firstSalesPerson * Math.pow(salesPersonMultiplier, gameState.salesForce));
  };
  const handleHireSalesPerson = () => {
    const salesPersonCost = calculateSalesPersonCost();

    if (gameState.availableFunds >= salesPersonCost) {
      setGameState((prevState) => ({
        ...prevState,
        availableFunds: prevState.availableFunds - salesPersonCost,
        salesForce: prevState.salesForce + 1,
      }));
    } else {
      alert('Not enough funds to hire a new salesperson.');
    }
  };
  
  //Automatic Factory: Cost & Buy
  const calculateFactoryCost = () => {
    return Math.round(firstFactory * Math.pow(factoryMultiplier, gameState.factories));
  };
  const handleBuyFactory = () => {
    const factoryCost = calculateFactoryCost();
    if (gameState.availableFunds >= factoryCost) {
      setGameState((prevState) => ({
        ...prevState,
        availableFunds: prevState.availableFunds - factoryCost,
        factories: prevState.factories + 1,
      }));
    } else {
      alert("Not enough funds to buy a new factory.");
    }
  };

  //Robotic Factory: Cost & Purchase
  const calculateRoboticFactoryCost = () => {
    return Math.round(firstRoboticFactory * Math.pow(roboticFactoryMultiplier, gameState.roboticFactories));
  }
  const handleBuyRoboticFactory = () => {
    const roboticFactoryCost = calculateRoboticFactoryCost();
    if (gameState.availableFunds >= roboticFactoryCost) {
      setGameState((prevState) => ({ 
        ...prevState,
        availableFunds: prevState.availableFunds - roboticFactoryCost,
        roboticFactories: prevState.roboticFactories + 1,
      }));
    } else {
      alert('Not enough funds to buy a new robotic factory.');
    }
  };

  //Showroom: Cost & Purchase
  const calculateShowroomCost = () => {
    return Math.round(firstShowroom * Math.pow(showroomMultiplier, gameState.showrooms));
  };
  const handleBuyShowroom = () => {
    const showroomCost = calculateShowroomCost();
    if (gameState.availableFunds >= showroomCost) {
      setGameState((prevState) => ({
        ...prevState,
        availableFunds: prevState.availableFunds - showroomCost,
        showrooms: prevState.showrooms + 1,
      }));
    } else {
      alert('Not enough funds to buy a new showroom.');
    }
  };

  //Upgrades
  const handleUpgrade = (upgradeType) => {
    const upgradeCosts = {
      productionEfficiency: Math.round(firstProductionEfficiency * Math.pow(productionEfficiencyMultiplier, gameState.upgrades.productionEfficiency)),
      premiumCarModel: Math.round(firstPremiumCarModel * Math.pow(premiumCarModelMultiplier, gameState.upgrades.premiumCarModel)),
      salesTraining: Math.round(firstSalesTraining * Math.pow(salesTrainingMultiplier, gameState.upgrades.salesTraining)),
      salesManager: Math.round(firstSalesManager * Math.pow(salesManagerMultiplier,gameState.upgrades.salesManager)),
      luxuryCarModel: Math.round(firstLuxuryCarModel * Math.pow(luxuryCarModelMultiplier, gameState.upgrades.luxuryCarModel)),
    };

    const upgradeCost = upgradeCosts[upgradeType];
    if (gameState.availableFunds >= upgradeCost) {
      setGameState((prevState) => ({
        ...prevState,
        availableFunds: prevState.availableFunds - upgradeCost,
        upgrades: {
          ...prevState.upgrades,
          [upgradeType] : prevState.upgrades[upgradeType] + 1,
        }
      }));
    } else {
      alert('Not enough funds to upgrade.');
    }
  };

  //Automatic Sales
  useEffect(() => {
    if (gameState.salesForce > 0) {
      const saleInterval = setInterval(() => {
        const salesBoost = Math.round(Math.pow(1.5, gameState.upgrades.salesTraining) - 1);
        const salesManagerBoost = gameState.upgrades.salesManager > 0 ? ((gameState.salesForce * gameState.upgrades.salesManager) - gameState.salesForce) : 0;
        const showroomBoost = gameState.showrooms * 10;

        setGameState((prevState) => {
          const carsToSell = prevState.salesForce + salesManagerBoost + Math.floor(prevState.salesForce * salesBoost) + showroomBoost;
          const actualCarsSold = Math.min(carsToSell, prevState.unsoldInventory);
          const carValueCalculation = carValue + gameState.upgrades.premiumCarModel * premiumCarValue + gameState.upgrades.luxuryCarModel * luxuryCarValue;
          const newUnsoldInventory = prevState.unsoldInventory - actualCarsSold;
          const newRevenue = prevState.revenue + (actualCarsSold * carValueCalculation);
          const newAvailableFunds = prevState.availableFunds + (actualCarsSold * carValueCalculation);

          let newStage = prevState.stage;
          //Transition to Stage 3 if revenue >= $1,000
          if (newRevenue >= stageProgressionTwo && prevState.stage === 2) {
            newStage = 3;
          }
          //Transition to Stage 4 if revenue > $50,000
          if (newRevenue > stageProgressionThree && prevState.stage === 3) {
            newStage = 4;
          }
          //Transition to next city if revenue > $100,000
          if (newRevenue > stageProgressionFour && prevState.stage === 4) {
            newStage = 5;
          }
          return {
            ...prevState,
            revenue: Math.round(newRevenue),
            availableFunds: Math.round(newAvailableFunds),
            unsoldInventory: newUnsoldInventory,
            stage: newStage,
          };
        });
      }, 1000); //every second
      return () => clearInterval(saleInterval); //Unmount Timer
    }
  }, [gameState.salesForce, gameState.upgrades, gameState.showrooms, stageProgressionTwo, stageProgressionThree, stageProgressionFour]);

  //Factory Production
  useEffect(() => {
    if (gameState.factories > 0 || gameState.roboticFactories > 0) {
      const factoryInterval = setInterval(() => {
      const carsProduced = gameState.factories + (gameState.roboticFactories * roboticFactoryProduction);
        setGameState((prevState) => ({
          ...prevState,
          unsoldInventory: prevState.unsoldInventory + carsProduced,
          carsProduced: prevState.carsProduced + carsProduced,
        }));
      }, 1000); //Every second
      return () => clearInterval(factoryInterval); //Unmount Timer
    }
  },[gameState.factories, gameState.roboticFactories]);

  //Marketing Ads
  const calculateAdCost = (adType) => {
    const adCosts = {
      tv: tvAdCost,
      billboard: billboardAdCost,
      digital: digitalAdCost,
    };
    return adCosts[adType];
  }
  const adOutputMultiplier = (adType) => {
    const adMultiplier = {
      tv: 2,
      billboard: 1.75,
      digital: 1.5
    }
    return adMultiplier[adType];
  }
  const handleRunAd = (adType) => {
    const adCost = calculateAdCost(adType);
    if (gameState.availableFunds >= adCost && gameState.activeAd === null) {
      setGameState((prevState) => ({
        ...prevState,
        availableFunds: prevState.availableFunds - adCost,
        activeAd: adType,
        salesForce: prevState.salesForce * adOutputMultiplier(adType), //Increase Sales Force
        adTimeRemaining: 10, //Ad time 10 seconds
      }));
    } else {
      if (gameState.availableFunds < adCost) {
        alert('Not enough funds to run this ad.');
      } else {
        alert('Another ad is currently running.');
      }  
    }
  };
  useEffect(() => {
    if (gameState.activeAd) {
      const adCountdown = setInterval(() => {
        setGameState((prevState) => {
          const newAdTimeRemaining = prevState.adTimeRemaining - 1;
          
          if (newAdTimeRemaining <= 0) {
            const updateSalesForce = prevState.salesForce / adOutputMultiplier(prevState.activeAd);
            return {
              ...prevState,
              activeAd: null,
              salesForce: updateSalesForce,
              adTimeRemaining: 0,
              cooldownAd: true,
              cooldownAdTimeRemaining: 60, // 1 minute cooldown
            };
          }
          return {
            ...prevState,
            adTimeRemaining: newAdTimeRemaining,
          };
        });
      },1000);
      return () => clearInterval(adCountdown); //Unmount Timer
    }
  }, [gameState.activeAd]);

  // Cooldown
  useEffect(() => {
    if (gameState.cooldownAd) {
      const cooldownTimer = setInterval(() => {
        setGameState((prevState) => {
          if (prevState.cooldownAdTimeRemaining <= 1) {
            clearInterval(cooldownTimer);
            return {
              ...prevState,
              cooldownAd: false, // Reset cooldown after it reaches 0
              cooldownAdTimeRemaining: 0, // Reset the countdown
            };
          }
          return {
            ...prevState,
            cooldownAdTimeRemaining: prevState.cooldownAdTimeRemaining - 1, // Decrement the timer
          };
        });
      }, 1000); // Update every second
  
      return () => clearInterval(cooldownTimer); // Cleanup on unmount
    }
  }, [gameState.cooldownAd]);

  return (
    <div className="App">
      <Header stage={gameState.stage} cityName={cityNames[gameState.currentCityIndex]} stage1={numberFormat(stageProgressionFirst)} stage2={numberFormat(stageProgressionTwo)} stage3={numberFormat(stageProgressionThree)} stage4={numberFormat(stageProgressionFour)} gameTime={timeFormat(gameState.elapsedTime)} />
      
      <div className="game">
        {gameState.stage === 1 && (
          <div className="tooltip">Next Upgrade: Produce {stageProgressionFirst - gameState.carsProduced} Cars to unlock Business Stage.</div>
        )}
        {gameState.stage === 2 && (
          <div className="tooltip">Next Upgrade: You need $ {numberFormat(stageProgressionTwo - gameState.revenue)} more in revenue to unlock Manufacturing Stage.</div>
        )}
        {gameState.stage === 3 && (
          <div className="tooltip">Next Upgrade: You need $ {numberFormat(stageProgressionThree - gameState.revenue)} more in revenue to unlock Marketing Stage.</div>
        )}
        {gameState.stage === 4 && (
          <div className="tooltip">Next Upgrade: You need $ {numberFormat(stageProgressionFour - gameState.revenue)} more in revenue to expand to new city.</div>
        )}
        {gameState.stage === 5 && (
          <div className="tooltip">Ready to move to next city? <Button className="inner-button" label="Lets Go!" onClick={handleCityTransition}/></div>
        )}
        <Button className="inner-button" label={`Reset`} onClick={resetGame} />
        <div className="stats">
          <ul>
            <li>Total Cars Produced: {numberFormat(gameState.carsProduced)}</li>
          </ul>
        </div>
        <div className="flexContainer">
          <Tooltip id="my-tooltip" style={{ backgroundColor: "#555", color: "#fff", width: "200px", textAlign: "center"}}  opacity={1}/>  
          <div className="leftColumn">
              <Button className="produce-car" label="Produce Car" onClick={handleProduceCar}/>
              {gameState.stage >= 2 && (
                <div className="business-tab">
                  <span className="innerHeading">Business</span>
                  <hr></hr>
                  <span>Revenue: $ {numberFormat(gameState.revenue)}</span><br />
                  <span>Available Funds: $ {numberFormat(gameState.availableFunds)}</span><br />
                  <span>Unsold Inventory: {numberFormat(gameState.unsoldInventory)}</span><br />
                  
                  <span>
                  {gameState.salesForce === 1 ? 'Salesperson' : 'Salespeople'}: {gameState.salesForce}
                  </span><br />
                  <div className="button-wrapper">
                    <Button className="inner-button" label={`Hire Salesperson ($${numberFormat(calculateSalesPersonCost())})`} onClick={handleHireSalesPerson}  disabled={gameState.availableFunds < calculateSalesPersonCost()}/>
                    <div className="tooltip-container">          
                      <span  data-tooltip-id="my-tooltip"
  data-tooltip-content="Hiring a salesperson increases sales by 1 car per second."
  data-tooltip-place="top"><span className="question-icon">?</span></span>
                  </div>
           </div>
                  
                </div>
              )}
              {gameState.stage >= 3 && (
                <div>
                  <span>{gameState.upgrades.salesManager === 1 ? 'Sales Manager' : 'Sales Managers'}: {gameState.upgrades.salesManager}</span><br />
                  <div className="button-wrapper">
                    <Button className="inner-button" label={`Hire Sales Manager ($${numberFormat(Math.round(firstSalesManager * Math.pow(salesManagerMultiplier,gameState.upgrades.salesManager)))})`} onClick={() => handleUpgrade('salesManager')} disabled={gameState.availableFunds < Math.round(firstSalesManager * Math.pow(salesManagerMultiplier,gameState.upgrades.salesManager))}/>
                    <div className="tooltip-container">
                    <span  data-tooltip-id="my-tooltip"
  data-tooltip-content="Boost sales by doubling the speed of your current sales team."
  data-tooltip-place="top"><span className="question-icon">?</span></span>
                    </div>
                  </div>
                </div>
              )}
              {gameState.stage >= 4 && (
                <div>
                  <span>{gameState.showrooms === 1 ? 'Showroom' : 'Showrooms'}: {gameState.showrooms}</span><br />
                  <div className="button-wrapper">
                    <Button className="inner-button" label={`Purchase Showroom ($${numberFormat(calculateShowroomCost())})`} onClick={handleBuyShowroom} disabled={gameState.availableFunds < calculateShowroomCost()} />
                    <div className="tooltip-container">
                      <span  data-tooltip-id="my-tooltip"
  data-tooltip-content="Expand your business by purchasing showrooms. Each showroom increases cars sold by 10 per second."
  data-tooltip-place="top"><span className="question-icon">?</span></span>
                    </div>
                  </div>
                </div>
              )}
              {gameState.stage >=3 && (
                <div>
                  <div className="manufacturing-tab">
                    <span className="innerHeading">Manufacturing</span>
                    <hr></hr>
                    <span>{gameState.factories === 1 ? 'Factory' : 'Factories'}: {gameState.factories}</span><br/>
                    <div className="button-wrapper">
                      <Button className="inner-button" label={`Buy Factory ($${numberFormat(calculateFactoryCost())})`} onClick={handleBuyFactory} disabled={gameState.availableFunds < calculateFactoryCost()} />
                      <div className="tooltip-container">
                      <span  data-tooltip-id="my-tooltip"
  data-tooltip-content="Increase car production with an automated factory. Each factory produces 1 car every 5 seconds."
  data-tooltip-place="top"><span className="question-icon">?</span></span>
                      </div>
                    </div>
                    {gameState.stage >= 4 && (
                      <div>
                        <span>{gameState.roboticFactories === 1 ? 'Robotic Factory' : 'Robotic Factories'}: {gameState.roboticFactories}</span><br/>
                        <div className="button-wrapper">
                          <Button className="inner-button" label={`Buy Robotic Factory ($${numberFormat(calculateRoboticFactoryCost())})`} onClick={handleBuyRoboticFactory} disabled={gameState.availableFunds < calculateRoboticFactoryCost()} />
                          <div className="tooltip-container">
                            <span  data-tooltip-id="my-tooltip"
        data-tooltip-content="Invest in robotic factories to automate production. Each factory produces 5 car per second."
        data-tooltip-place="top"><span className="question-icon">?</span></span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
          </div>
          <div className="rightColumn">
            {gameState.stage >= 3 && (
                <div className="upgrades-tab">
                  <span className="innerHeading">Upgrades</span>
                  <hr></hr>
                  <span>Production Efficiency: Level {gameState.upgrades.productionEfficiency} </span>
                  <div className="button-wrapper">
                    <Button className="inner-button" label={`Upgrade Production Efficiency ($${numberFormat(Math.round(firstProductionEfficiency * Math.pow(productionEfficiencyMultiplier, gameState.upgrades.productionEfficiency)))})`} onClick={() => handleUpgrade('productionEfficiency')} disabled={gameState.availableFunds < Math.round(firstProductionEfficiency * Math.pow(productionEfficiencyMultiplier, gameState.upgrades.productionEfficiency))} />
                    <div className="tooltip-container">
                    <span  data-tooltip-id="my-tooltip"
  data-tooltip-content="Enhance your manual production. Each upgrade increases cars produced per click."
  data-tooltip-place="top"><span className="question-icon">?</span></span>
                    </div>
                  </div>
                </div>

            )}
            {gameState.stage === 3 && (
              <div>
                <span>Premium Car Model: Level {gameState.upgrades.premiumCarModel}</span>
                <div className="button-wrapper">
                  <Button className="inner-button" label={`Upgrade Premium Car Model ($${numberFormat(Math.round(firstPremiumCarModel * Math.pow(premiumCarModelMultiplier, gameState.upgrades.premiumCarModel)))})`} onClick={() => handleUpgrade('premiumCarModel')} disabled={gameState.availableFunds < Math.round(firstPremiumCarModel * Math.pow(premiumCarModelMultiplier, gameState.upgrades.premiumCarModel))} />
                  <div className="tooltip-container">
                  <span  data-tooltip-id="my-tooltip"
  data-tooltip-content="Increase the value of each car sold. Each level adds $10 to the car's price."
  data-tooltip-place="top"><span className="question-icon">?</span></span>
                  </div>
                </div>
                
                {/* <br /><span>Sales Training: Level {gameState.upgrades.salesTraining}</span>
                <div className="button-wrapper">
                  <Button className="inner-button" label={`Upgrade Sales Training ($${numberFormat(Math.round(firstSalesTraining * Math.pow(salesTrainingMultiplier, gameState.upgrades.salesTraining)))})`} onClick={() => handleUpgrade('salesTraining')} />
                  <div className="tooltip-container">
                  <span  data-tooltip-id="my-tooltip"
  data-tooltip-content="Improve the efficiency of your sales team, increasing cars sold per second."
  data-tooltip-place="top"><span className="question-icon">?</span></span>
                  </div>
                </div> */}
              </div>
            )}
            {gameState.stage >= 4 && (
              <div>
                <span>Luxury Car Model: Level {gameState.upgrades.luxuryCarModel}</span>
                <div className="button-wrapper">
                  <Button className="inner-button" label={`Upgrade Luxury Car Model ($${numberFormat(Math.round(firstLuxuryCarModel * Math.pow(luxuryCarModelMultiplier, gameState.upgrades.luxuryCarModel)))})`} onClick={() => handleUpgrade('luxuryCarModel')} disabled={gameState.availableFunds < Math.round(firstLuxuryCarModel * Math.pow(luxuryCarModelMultiplier, gameState.upgrades.luxuryCarModel))} />
                  <div className="tooltip-container">
                    <span  data-tooltip-id="my-tooltip"
    data-tooltip-content="Enhance to luxury car models. Each upgrade increases the car value by $30, boosting your profits."
    data-tooltip-place="top"><span className="question-icon">?</span></span>
                  </div>
                </div>
                <div className="marketing-tab">
                  <span className="innerHeading">Marketing</span>
                  <hr></hr>
                  <div className="button-wrapper">
                    <Button className="inner-button" onClick={() => handleRunAd('tv')} label={`TV Ads ($${numberFormat(calculateAdCost('tv'))})`} disabled={gameState.activeAd || gameState.cooldownAd || gameState.availableFunds < calculateAdCost('tv')} />
                    <div className="tooltip-container">
                    <span  data-tooltip-id="my-tooltip"
      data-tooltip-content="Run TV advertisements to double your sales force for 10 seconds. A powerful marketing tool!"
      data-tooltip-place="top"><span className="question-icon">?</span></span>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <Button className="inner-button" onClick={() => handleRunAd('billboard')} label={`Billboard Ads ($${numberFormat(calculateAdCost('billboard'))})`} disabled={gameState.activeAd || gameState.cooldownAd || gameState.availableFunds < calculateAdCost('billboard')} />
                    <div className="tooltip-container">
                    <span  data-tooltip-id="my-tooltip"
      data-tooltip-content="Promote your cars with billboard advertisements. Boosts sales force by 1.75x for 10 seconds."
      data-tooltip-place="top"><span className="question-icon">?</span></span>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <Button className="inner-button" onClick={() => handleRunAd('digital')} label={`Digital Ads ($${numberFormat(calculateAdCost('digital'))})`} disabled={gameState.activeAd || gameState.cooldownAd || gameState.availableFunds < calculateAdCost('digital')} />
                    <div className="tooltip-container">
                    <span  data-tooltip-id="my-tooltip"
      data-tooltip-content="Engage potential customers through digital advertising. Increases sales force by 1.5x for 10 seconds."
      data-tooltip-place="top"><span className="question-icon">?</span></span>
                    </div>
                  </div>
                  {gameState.activeAd && <p>{gameState.activeAd.toUpperCase()} Ad running for {timeFormat(gameState.adTimeRemaining)}</p>}
                  {/* {Math.floor(gameState.adTimeRemaining / 60)}:{gameState.adTimeRemaining % 60 < 10 ? '0' : ''}{gameState.adTimeRemaining % 60} */}
                  {gameState.cooldownAd && (<div className="cooldown-timer">Cooldown: {gameState.cooldownAdTimeRemaining}s</div>)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

}

export default App;